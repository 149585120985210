import { Colors } from "./types";

export const baseColors = {
  failure: "#191919",
  primary: "#fcd535",
  primaryBright: "#53DEE9",
  primaryDark: "#FFFFFF",
  secondary: "#FFFFFF",
  success: "#FCD535",
  warning: "#FFB237",
  addrees_color:'#191919'
};

export const additionalColors = {
  binance: '#FFFFFF',
  overlay: '#FFFFFF', 
  gold: '#FFFFFF',
  silver: '#FFFFFF', 
  bronze: '#FFFFFF',
};

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: '#2c2c2c', 
  backgroundDisabled: '#78682f',
  backgroundAlt: '#2c2c2c',
 Tabbackground:'#2c2c2c',
  // backgroundAlt2: 'rgba(255, 255, 224, 0.7)',
  backgroundAlt2: 'rgba(25,25,25, 0.7)',
  cardBorder: '#2c2c2c', 
  contrast: '#2c2c2c', 
  dropdown: '#2c2c2c', 
  dropdownDeep: '#2c2c2c',
  invertedContrast: '#2c2c2c',
  input: '#191919', 
  inputSecondary: '#191919', 
  tertiary: '#fcd535', 
  text: '#FFFFFF', 
  textDisabled: '#191919', 
  textSubtle: '#8C8C8C',
  disabled: '#FFFFFF', 
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #191919 0%, #191919 100%)',
    inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
    cardHeader: "linear-gradient(111.68deg, #2c2c2c 0%, #2c2c2c 100%)",
    blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
    violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
    violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
    gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
  },
};

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: '#2c2c2c', 
  backgroundDisabled: '#78682f',
  backgroundAlt: '#2c2c2c',
 Tabbackground:'#2c2c2c',

  // backgroundAlt2: 'rgba(255, 255, 224, 0.7)',
  backgroundAlt2: 'rgba(25,25,25, 0.7)',
  cardBorder: '#2c2c2c', 
  contrast: '#2c2c2c', 
  dropdown: '#2c2c2c', 
  dropdownDeep: '#2c2c2c',
  invertedContrast: '#2c2c2c',
  input: '#191919', 
  inputSecondary: '#191919', 
  tertiary: '#fcd535', 
  text: '#FFFFFF', 
  textDisabled: '#191919', 
  textSubtle: '#8C8C8C',
  disabled: '#FFFFFF', 
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #191919 0%, #191919 100%)',
    inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
    cardHeader: "linear-gradient(111.68deg, #2c2c2c 0%, #2c2c2c 100%)",
    blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
    violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
    violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
    gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
  },
};
