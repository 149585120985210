import styled from 'styled-components';
import { ChevronDownIcon } from "../../packages/uikit/src/components/Svg";

const StyledPage = styled.div`
  background: #2C2C2C;
  border-radius: 8px;
  padding:4px;
  display: flex;
  align-items: center;
  .img-fluid{
    width:24px;
    height:24px;
  }
  .chian-name{}
`

const NetworkSwitcher = ()=>{
  return(
    <StyledPage>
       <img className="img-fluid" alt="" src="/images/index/bnb-icon.png"/>
       {/* <div className="chian-name">BNB Chain</div> */}
       <ChevronDownIcon color="#979797" width="24px" />
    </StyledPage>
  )
}
export default NetworkSwitcher