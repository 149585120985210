import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="首页" transform="translate(-174, -54)" fill-rule="nonzero">
            <g id="网络" transform="translate(174, 54)">
                <rect id="矩形" x="0" y="0" width="24" height="24"></rect>
                <path d="M22.4985648,12.175387 C22.404918,17.8934751 17.7404091,22.5 12,22.5 C6.20101525,22.5 1.5,17.7989847 1.5,12 L1.50143523,11.824613 C1.59508203,6.10652489 6.25959085,1.5 12,1.5 C17.7989847,1.5 22.5,6.20101525 22.5,12 L22.4985648,12.175387 Z M20.4415,13 L3.558,13 L3.57239022,13.1143532 C4.11132508,17.230074 7.59463937,20.4194059 11.8384803,20.4984961 L12,20.5 C16.3167037,20.5 19.881853,17.2821717 20.4276098,13.1143532 L20.4415,13 Z M12,3.5 C7.68329632,3.5 4.11814704,6.7178283 3.57239022,10.8856468 L3.558,11 L20.4415,11 L20.4276098,10.8856468 C19.8886749,6.76992603 16.4053606,3.58059414 12.1615197,3.50150393 L12,3.5 Z" id="形状" fill="#FFFFFF"></path>
                <path d="M12,2 C14.9830439,2 17,6.53815132 17,12 C17,17.4618487 14.9830439,22 12,22 C9.01695614,22 7,17.4618487 7,12 C7,6.53815132 9.01695614,2 12,2 Z M12,4 C10.5647659,4 9,7.52072319 9,12 C9,16.4792768 10.5647659,20 12,20 C13.4352341,20 15,16.4792768 15,12 C15,7.52072319 13.4352341,4 12,4 Z" id="椭圆形" fill="#FFFFFF"></path>
            </g>
        </g>
    </g>
      {/* <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z" /> */}
    </Svg>
  );
};

export default Icon;
