import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="首页-流动性-连接钱包" transform="translate(-51, -738)" fill-rule="nonzero">
            <g id="资金兑换" transform="translate(51, 738)">
                <rect id="矩形" x="0" y="0" width="24" height="24"></rect>
                <path d="M19.8943278,6.61967562 C20.3805175,6.35768709 20.9870359,6.53943807 21.2490244,7.0256278 C22.0660774,8.54188801 22.5,10.2407013 22.5,12 C22.5,17.7989847 17.7989847,22.5 12,22.5 C10.2407013,22.5 8.54188801,22.0660774 7.0256278,21.2490244 C6.53943807,20.9870359 6.35768709,20.3805175 6.61967562,19.8943278 C6.88166415,19.4081381 7.48818247,19.2263871 7.9743722,19.4883756 C9.20084597,20.1492741 10.5739429,20.5 12,20.5 C16.6944153,20.5 20.5,16.6944153 20.5,12 C20.5,10.5739429 20.1492741,9.20084597 19.4883756,7.9743722 C19.2263871,7.48818247 19.4081381,6.88166415 19.8943278,6.61967562 Z M12,1.5 C13.7433899,1.5 15.4275679,1.92613425 16.933735,2.72919699 C17.4210755,2.98903867 17.6054997,3.59474949 17.345658,4.08208997 C17.0858163,4.56943046 16.4801055,4.75385468 15.992765,4.49401301 C14.7744689,3.84443818 13.4131721,3.5 12,3.5 C7.30558475,3.5 3.5,7.30558475 3.5,12 C3.5,13.3383966 3.80887101,14.6305294 4.39418919,15.7996107 C4.64144115,16.2934578 4.44153633,16.8942372 3.94768928,17.1414892 C3.45384222,17.3887412 2.85306277,17.1888363 2.60581081,16.6949893 C1.88210092,15.2494922 1.5,13.651009 1.5,12 C1.5,6.20101525 6.20101525,1.5 12,1.5 Z" id="形状" fill="#8C8C8C"></path>
                <path d="M5.46745,18.8422 C5.4504,19.2267 5.0235,19.4486 4.69902,19.2416 L1.778545,17.3786 C1.45405,17.1716 1.475325,16.69095 1.816845,16.5134 L4.890505,14.9157 C5.232,14.7382 5.63765,14.99695 5.6206,15.3815 L5.46745,18.8422 Z" id="路径" fill="#8C8C8C"></path>
                <path d="M18.6812,5.20465 C18.7104,4.82087 19.1441,4.612605 19.46185,4.82978 L22.32185,6.7844 C22.63965,7.00155 22.60315,7.4813 22.25615,7.6479 L19.13345,9.14745 C18.78645,9.31405 18.38925,9.0426 18.41845,8.6588 L18.6812,5.20465 Z" id="路径" fill="#8C8C8C"></path>
                <path d="M12.424,17.232 C12.136,17.232 11.992,17.14 11.992,16.956 L11.992,16.668 C11.48,16.628 11,16.5 10.552,16.284 C10.112,16.06 9.728,15.728 9.4,15.288 C9.248,15.072 9.2,14.872 9.256,14.688 C9.32,14.496 9.456,14.34 9.664,14.22 C9.872,14.1 10.06,14.068 10.228,14.124 C10.404,14.172 10.572,14.292 10.732,14.484 C10.892,14.66 11.072,14.804 11.272,14.916 C11.48,15.028 11.72,15.104 11.992,15.144 L11.992,12.864 C11.48,12.744 11.032,12.588 10.648,12.396 C10.264,12.204 9.964,11.948 9.748,11.628 C9.54,11.3 9.436,10.888 9.436,10.392 C9.436,9.696 9.664,9.12 10.12,8.664 C10.584,8.208 11.208,7.948 11.992,7.884 L11.992,7.572 C11.992,7.428 12.04,7.336 12.136,7.296 C12.232,7.256 12.328,7.236 12.424,7.236 C12.712,7.236 12.856,7.348 12.856,7.572 L12.856,7.908 C13.352,7.964 13.788,8.096 14.164,8.304 C14.548,8.512 14.864,8.76 15.112,9.048 C15.272,9.24 15.332,9.432 15.292,9.624 C15.252,9.808 15.132,9.976 14.932,10.128 C14.732,10.28 14.544,10.34 14.368,10.308 C14.192,10.268 14.016,10.156 13.84,9.972 C13.712,9.844 13.568,9.732 13.408,9.636 C13.248,9.54 13.064,9.468 12.856,9.42 L12.856,11.496 C13.784,11.72 14.456,12.056 14.872,12.504 C15.296,12.944 15.508,13.484 15.508,14.124 C15.508,14.9 15.268,15.504 14.788,15.936 C14.316,16.36 13.672,16.604 12.856,16.668 L12.856,16.956 C12.856,17.052 12.808,17.12 12.712,17.16 C12.616,17.208 12.52,17.232 12.424,17.232 Z M12.856,15.168 C13.256,15.12 13.524,15.008 13.66,14.832 C13.796,14.656 13.864,14.432 13.864,14.16 C13.864,13.928 13.804,13.736 13.684,13.584 C13.572,13.432 13.296,13.28 12.856,13.128 L12.856,15.168 Z M11.992,11.244 L11.992,9.372 C11.704,9.42 11.476,9.52 11.308,9.672 C11.14,9.824 11.056,10.036 11.056,10.308 C11.056,10.412 11.072,10.516 11.104,10.62 C11.144,10.724 11.228,10.828 11.356,10.932 C11.492,11.036 11.704,11.14 11.992,11.244 Z" id="形状" fill="#8C8C8C"></path>
            </g>
        </g>
    </g>
    </Svg>
  );
};

export default Icon;
