import {
  Cards,
  MenuItemsType,
  SwapIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import LP from '@pancakeswap/uikit/src/components/Svg/Icons/LP'
import ArrowForward from "@pancakeswap/uikit/src/components/Svg/Icons/ArrowForward";

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  {
    label: t('交易'),
    icon: SwapIcon,
    fillIcon: SwapIcon,
    href: '/swap',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('添加流动性'),
    icon: LP,
    fillIcon: LP,
    href: '/liquidity',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('生态系统'),
    icon: Cards,
    fillIcon: Cards,
    href: 'https://wukongfi.netlify.app/?utm_source=tokenpocket#/?id=0x3e59e86432dc3475526735452e10d91a98cd85ad',
    showItemsOnMobile: false,
    items: [],
  }
]

export default config
